exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-book-list-tsx": () => import("./../../../src/pages/book-list.tsx" /* webpackChunkName: "component---src-pages-book-list-tsx" */),
  "component---src-pages-book-shelf-tsx": () => import("./../../../src/pages/book-shelf.tsx" /* webpackChunkName: "component---src-pages-book-shelf-tsx" */),
  "component---src-pages-covid-tsx": () => import("./../../../src/pages/covid.tsx" /* webpackChunkName: "component---src-pages-covid-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mothersday-tsx": () => import("./../../../src/pages/mothersday.tsx" /* webpackChunkName: "component---src-pages-mothersday-tsx" */),
  "component---src-pages-pronounce-tsx": () => import("./../../../src/pages/pronounce.tsx" /* webpackChunkName: "component---src-pages-pronounce-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-highlight-json-tsx": () => import("./../../../src/templates/highlight-json.tsx" /* webpackChunkName: "component---src-templates-highlight-json-tsx" */),
  "component---src-templates-highlights-tsx": () => import("./../../../src/templates/highlights.tsx" /* webpackChunkName: "component---src-templates-highlights-tsx" */)
}

